interface Publication {
  period: string,
  title: string,
  authors: string,
  journal: string,
  page: string,
  honors?: string,
}

const publications: Array<Publication> = [
  {
    period: "2025",
    title: "SpatIO: Sketch-Based Visualization of Problem Decomposition in Block-Based Programming",
    authors: "Seung Hyeon Han, Yeeun Han, Kyeongho Park, Sangjun Lee, Woohun Lee",
    journal: "CHI ’25",
    page: "accepted",
  },
  {
    period: "2025",
    title: "Desk Console: Augmenting 3D Virtual Controls on Physical Desks for Immersive Authoring",
    authors: "Yeeun Shin, Seung Hyeon Han, Woohun Lee",
    journal: "CHI EA ’25",
    page: "accepted",
  },
  {
    period: "2023",
    title: "Sketchnote: Sketch-Based Visualization of Problem Decomposition in Block-Based Programming",
    authors: "Seung Hyeon Han, Woohun Lee",
    journal: "UIST ’23 Adjunct",
    page: "pp. 1-3",
  },
  {
    period: "2022",
    title: "블록 기반 프로그래밍에서 문제 분해의 스케치 기반 시각화",
    authors: "한승현",
    journal: "석사학위논문, 한국과학기술원",
    page: "대전",
  },
  {
    period: "2022",
    title: "SurfaceLens: near-surface spatial interaction with science museum exhibits",
    authors: "HyunBeom Yi, Seung Hyeon Han, Min Kyoung Park, Woohun Lee",
    journal: "Digital Creativity",
    page: "33(1), 18-34",
    honors: "Cover Paper",
  },
  {
    period: "2020",
    title: "Defining basic archetypes of an intelligent agent’s behaviour in an open-ended interactive environment",
    authors: "Richard Chulwoo Park, Donghyeon Ko, Hyunjung Kim, Seung Hyeon Han, Jee Bin Lim, Jiseong Goo, Geehyuk Lee & Woohun Lee",
    journal: "Digital Creativity",
    page: "31(1), 65-81",
  },
];

export default publications;
